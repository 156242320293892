import {
  Box,
  Button,
  Card,
  CircularProgressBar,
  Image,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Notification,
  Page,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";
import Cc from "./cc.png";
import { useTranslation } from "react-i18next"
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree === true;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getTrial();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/circular/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`https://certifiedcode.wixsite.com/circular/_functions/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=1e9b9e8f-6833-4ec0-b6a7-ef1ebd4755d6&redirectUrl=https://certifiedcode.wixsite.com/circular/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  console.log("isUpgradeBannerOpen", isUpgradeBannerOpen, instanceData);
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>{t('upgrade-title')}</Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${"1e9b9e8f-6833-4ec0-b6a7-ef1ebd4755d6"}?appInstanceId=${
                (instanceData as any)["instance"]["instanceId"]
              }`
            );
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          className="close-button"
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle" direction="vertical">
              <MarketingPageLayoutContent
                title={t('circular-title')}
                content={t('circular-content')}
                actions={
                  <Box direction="vertical" gap="SP1">
                    <Card>
                      <Card.Content>
                        <CircularProgressBar
                          label={
                            isUpgraded
                              ? t('premium-msg')
                              : t('premium-update', {trialRemainingDate} )
                          }
                          skin={isUpgraded ? "standard" : "premium"}
                          showProgressIndication
                          value={isUpgraded ? 100 : 1 - trialRemainingDate / 7}
                        />
                      </Card.Content>
                    </Card>
                    <Button
                      skin="premium"
                      disabled={isUpgraded}
                      prefixIcon={<Icons.PremiumFilled />}
                      onClick={() => {
                        window.open(
                          `https://www.wix.com/apps/upgrade/${"1e9b9e8f-6833-4ec0-b6a7-ef1ebd4755d6"}?appInstanceId=${
                            (instanceData as any)["instance"]["instanceId"]
                          }`
                        );
                      }}
                    >
                      {isUpgraded ? t('manage-plan-button') : t('upgrade-premium-button')}
                    </Button>
                    <Button
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/1e9b9e8f-6833-4ec0-b6a7-ef1ebd4755d6"
                      target="_blank"
                    >
                      {t('circular-rate-button')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Cc} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
